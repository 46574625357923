import Fuse from 'fuse.js';
import axios from '../configs/axios'

const search_input       = document.getElementById('search_input')
const search_results     = document.getElementById("search_results")

if(search_input && search_results) {

axios.get('site-index.json').then(function (site) {

  const fuse = new Fuse(site, {
    shouldSort: true,
    includeMatches: true,
    threshold: 0.4,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ["tt","tv"]
  })

  search_input.addEventListener('input', e => {

    search_results.innerHTML = ''
    let results = fuse.search(search_input.value)

    results.forEach((resultItem) => {
      highlighter(resultItem);
    })

    let results_html       = ''
    let results_ordered    = {}

    let total_count = 0
    results.every(function(result, index) {
      if(!results_ordered[result.item.type]) {
        results_ordered[result.item.type] = { 'count': 0, 'html': '' }
      }

      if(results_ordered[result.item.type]['count'] < 8) {
        results_ordered[result.item.type]['count']++;
        total_count++;
        results_ordered[result.item.type]['html'] += '<a href="/' + result.item.type + '/'+ result.item.id+'">' +
          '<h3>' + (result.highlight_key == 'tt' ? result.highlight : ((result.item.tv) ? result.item.tv : result.item.tt)) + '</h3>' +
          '<small>' + (result.highlight_key == 'tv' ? result.highlight : ((result.item.tv) ? result.item.tt : '')) + '</small>' +
          '<span class="lang">' + result.item.ln + '</span>' +
          '</a>'
      }

      return (total_count > 24) ? false : true
    })

    //for (let index in results_ordered) {
    results_html += results_ordered['bibles'] ? '<div class="bibles medium-12"><h2 data-i18n="bibles">Bibles</h2>' + results_ordered['bibles']['html'] + '</div>' : ''
    results_html += results_ordered['films'] ? '<div class="films medium-6"><h2 data-i18n="films">Films</h2>' + results_ordered['films']['html'] + '</div>' : ''
    results_html += results_ordered['resources'] ? '<div class="resources medium-6"><h2 data-i18n="resources">resources</h2>' + results_ordered['resources']['html'] + '</div>' : ''
    results_html += results_ordered['languages'] ? '<div class="languages medium-6"><h2 data-i18n="languages">languages</h2>' + results_ordered['languages']['html'] + '</div>' : ''
    results_html += results_ordered['countries'] ? '<div class="countries medium-6"><h2 data-i18n="countries">countries</h2>' + results_ordered['countries']['html'] + '</div>' : ''
    results_html += results_ordered['organization'] ? '<div class="organization medium-6"><h2 data-i18n="organization">organization</h2>' + results_ordered['organization']['html'] + '</div>' : ''
    //}

    search_results.innerHTML = results_html
  })
})

let highlighter = function(resultItem){
  resultItem.matches.forEach((matchItem) => {
    let text = resultItem.item[matchItem.key]
    let result = []
    let matches = [].concat(matchItem.indices)
    let pair = matches.shift()

    for (let i = 0; i < text.length; i++) {
      let char = text.charAt(i)
      if (pair && i == pair[0]) {
        result.push('<b>')
      }
      result.push(char)
      if (pair && i == pair[1]) {
        result.push('</b>')
        pair = matches.shift()
      }
    }
    resultItem.highlight = result.join('')
    resultItem.highlight_key = matchItem.key

    if(resultItem.children && resultItem.children.length > 0){
      resultItem.children.forEach((child) => {
        highlighter(child);
      });
    }
  })
}

}
